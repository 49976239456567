var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],staticClass:"py-2 position-relative",style:({
    height: _vm.fullHeight ? '100%' : 'auto'
  }),attrs:{"tile":""}},[_c('v-system-bar',{attrs:{"color":_vm.flow.archived
        ? 'grey'
        : _vm.loading > 0
        ? 'secondaryGray'
        : _vm.filteredErrors && _vm.filteredErrors.length > 0
        ? 'Failed'
        : 'Success',"height":5,"absolute":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('CardTitle',{attrs:{"title":((_vm.filteredErrors ? _vm.filteredErrors.length : 0) + " Recent Task Failures"),"icon":"error","icon-color":_vm.flow.archived || _vm.loading > 0
            ? 'grey'
            : _vm.filteredErrors && _vm.filteredErrors.length > 0
            ? 'Failed'
            : 'Success',"loading":_vm.errors && _vm.errors.length === 0 && _vm.loading > 0}},[_c('div',_vm._g({attrs:{"slot":"action"},slot:"action"},on),[(!_vm.flow.archived)?_c('v-select',{staticClass:"time-interval-picker",attrs:{"data-public":"","items":_vm.dateFilters,"dense":"","solo":"","item-text":"name","item-value":"value","hide-details":"","flat":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"utilGrayDark","x-small":""}},[_vm._v(" history ")])]},proxy:true}],null,true),model:{value:(_vm.selectedDateFilter),callback:function ($$v) {_vm.selectedDateFilter=$$v},expression:"selectedDateFilter"}}):_vm._e()],1)])]}}])},[_c('span',[_vm._v(" Filter by when runs were last updated ")])]),_c('v-list',{staticClass:"error-card-content"},[(!_vm.errors && _vm.loading > 0)?_c('v-slide-y-reverse-transition',{attrs:{"mode":"out-in","leave-absolute":"","group":""}},[_c('v-skeleton-loader',{key:"skeleton",attrs:{"type":"list-item-three-line"}})],1):(_vm.filteredErrors && _vm.filteredErrors.length > 0)?_c('v-slide-y-reverse-transition',{attrs:{"leave-absolute":"","mode":"out-in","group":""}},_vm._l((_vm.filteredErrors),function(error){return _c('v-lazy',{key:error.id,attrs:{"options":{
          threshold: 0.75
        },"min-height":"40px","transition":"fade"}},[_c('v-list-item',{attrs:{"to":{
            name: 'task-run',
            params: { id: error.id },
            query: {
              logId: ''
            }
          }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"text-truncate d-block ma-0 pa-0",staticStyle:{"max-width":"95%"}},[_vm._v(" "+_vm._s(error.task.name)+" ")])]),_c('v-list-item-subtitle',{staticClass:"font-weight-light"},[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.formatTimeRelative(error.state_timestamp))+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-light Failed--text text--darken-1"},[_vm._v(" "+_vm._s(error.state_message)+" ")])])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("arrow_right")])],1)],1)],1)}),1):_c('v-slide-y-transition',{attrs:{"leave-absolute":"","mode":"out-in","group":""}},[_c('v-list-item',{key:"no-data",attrs:{"color":"grey"}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"green--text"},[_vm._v("check")])],1),_c('v-list-item-content',{staticClass:"my-0 py-0"},[(!_vm.flow.archived)?_c('div',{staticClass:"text-subtitle-1 font-weight-light",staticStyle:{"line-height":"1.25rem"}},[_vm._v(" No reported errors in the last "+_vm._s(_vm.selectedDateFilter)+"... Everything looks good! ")]):_c('div',{staticClass:"text-subtitle-1 font-weight-light",staticStyle:{"line-height":"1.25rem"}},[_vm._v(" No errors were reported in the last "+_vm._s(_vm.selectedDateFilter)+" before this version was archived. ")])])],1)],1)],1),(_vm.errors && _vm.errors.length > 3)?_c('div',{staticClass:"pa-0 error-footer"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }