var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],staticClass:"pa-2 mt-2",attrs:{"outlined":""}},[_c('CardTitle',{attrs:{"title":"Cloud Hooks","icon":"cloud"}}),_c('v-card-text',{staticClass:"pl-12"},[_c('div',{staticClass:"text-body-1",staticStyle:{"max-width":"1000px"}},[_vm._v(" Cloud Hooks allow you to send notifications to Slack, email, or any other endpoint when your flow enters a given state. For example, send a Slack message to your team when a production-critical flow has failed, along with the reason for the failure, so you can respond immediately. Note that some Cloud Hooks such as email are only available with Prefect Cloud. ")]),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onD = ref.onD;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"pb-1 text-center",class:_vm.containerClass,style:({
                        display: !_vm.cloudHooks ? 'block' : 'inline-block'
                      })},on),[_c('v-btn',_vm._g({attrs:{"color":"primary","small":!!_vm.cloudHooks,"large":!_vm.cloudHooks,"disabled":!_vm.hasPermission('create', 'cloud-hook')},on:{"click":function($event){_vm.createNewCloudHook = true}}},onD),[_c('v-icon',{attrs:{"left":""}},[_vm._v("cloud")]),_vm._v(" New Cloud Hook ")],1),(!_vm.cloudHooks)?_c('div',{staticClass:"mt-8 text-body-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.flow.name))]),_vm._v(" has no Cloud Hooks! Create one using the button above. ")]):_vm._e()],1)]}}],null,true)},[(!_vm.hasPermission('create', 'cloud-hook'))?_c('span',[_vm._v(" You don't have permission to create new Cloud Hooks. ")]):_c('span',[_vm._v(" Create a new Cloud Hook for this Flow ")])])]}}]),model:{value:(_vm.createNewCloudHook),callback:function ($$v) {_vm.createNewCloudHook=$$v},expression:"createNewCloudHook"}},[_c('v-card',{staticClass:"pa-2",attrs:{"tile":""}},[_c('CardTitle',{attrs:{"title":"New Cloud Hook","icon":"cloud"}}),_c('v-card-text',{staticClass:"pl-12"},[(_vm.createNewCloudHook)?_c('CloudHookForm',{attrs:{"editable":_vm.hasPermission('create', 'cloud-hook') &&
                        _vm.hasPermission('delete', 'cloud-hook'),"edit-on-render":"","version-group-id-prop":_vm.flow.version_group_id},on:{"close":function($event){_vm.createNewCloudHook = false},"update":function($event){return _vm.$apollo.queries.cloudHooks.refetch()}}}):_vm._e()],1)],1)],1)],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"focusable":"","tile":"","hover":"","flat":""}},_vm._l((_vm.cloudHooks),function(item){return _c('v-expansion-panel',{key:item.id,style:({
                    'border-left': ("2px solid var(--v-" + (_vm.stateGroupColor(
                      _vm.stateGroup(item.states)
                    )) + "-base) !important")
                  })},[_c('v-lazy',{attrs:{"options":{
                      threshold: 0.75
                    },"min-height":"40px","transition":"fade"}},[_c('div',[_c('v-expansion-panel-header',[_c('v-progress-linear',{attrs:{"active":item.loading,"indeterminate":item.loading,"color":_vm.stateGroupColor(_vm.stateGroup(item.states)),"background-opacity":"0","absolute":"","bottom":""}}),_c('v-list-item',{staticClass:"pl-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"mr-6",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.typeIcon(item.type))+" ")])],1),_c('v-col',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"justify-self-start"},[_c('div',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.typeTitle(item.type))+" ")])]),_c('div',{staticClass:"justify-self-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_c('div',{staticClass:"vertical-button"},[_c('v-switch',{staticClass:"v-input--vertical",attrs:{"hide-details":"","color":"primary","loading":item.loading,"disabled":(!_vm.hasPermission(
                                                  'create',
                                                  'cloud-hook'
                                                ) &&
                                                  !_vm.hasPermission(
                                                    'delete',
                                                    'cloud-hook'
                                                  )) ||
                                                  item.loading},on:{"change":function($event){return _vm._handleSetCloudHookStatusChange(
                                                  $event,
                                                  item
                                                )}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-btn',{staticClass:"mb-1",attrs:{"tile":"","small":"","text":"","disabled":""}},[_vm._v(" "+_vm._s(item.active ? 'On' : 'Off')+" ")])]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)])]}}],null,true)},[(
                                          !_vm.hasPermission(
                                            'update',
                                            'cloud-hook'
                                          )
                                        )?_c('span',[_vm._v(" You don't have permission to change Cloud Hook states. ")]):_c('span',[_vm._v(" "+_vm._s(item.active ? 'Deactivate' : 'Activate')+" this Cloud Hook ")])])],1)]),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-body-2"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(" States: ")]):_vm._e(),_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","dark":"","color":_vm.stateGroupColor(
                                          _vm.stateGroup(item.states)
                                        )}},[_vm._v(" "+_vm._s(_vm.stateGroup(item.states))+" ")])],1)]):_vm._e()],1)],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('CloudHookForm',{attrs:{"hook":item,"editable":_vm.hasPermission('create', 'cloud-hook') &&
                              _vm.hasPermission('delete', 'cloud-hook'),"show-controls":"","version-group-id-prop":_vm.flow.version_group_id,"loading":item.loading},on:{"update:loading":function($event){return _vm.$set(item, "loading", $event)},"update":function($event){return _vm.$apollo.queries.cloudHooks.refetch()}}})],1)],1)])],1)}),1)],1),_c('v-col',{attrs:{"cols":"0","md":"2"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }