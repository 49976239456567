var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],staticClass:"pa-2",style:({
    height: _vm.fullHeight ? '100%' : 'auto'
  }),attrs:{"tile":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('CardTitle',{attrs:{"title":"Flow Runs Summary","icon":"pi-flow-run"}},[_c('div',_vm._g({attrs:{"slot":"action"},slot:"action"},on),[(!_vm.flow.archived)?_c('v-select',{staticClass:"time-interval-picker",attrs:{"data-public":"","items":_vm.dateFilters,"dense":"","solo":"","item-text":"name","item-value":"value","hide-details":"","flat":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"utilGrayDark","x-small":""}},[_vm._v(" history ")])]},proxy:true}],null,true),model:{value:(_vm.selectedDateFilter),callback:function ($$v) {_vm.selectedDateFilter=$$v},expression:"selectedDateFilter"}}):_vm._e()],1)])]}}])},[_c('span',[_vm._v(" Filter by when runs were last updated ")])]),_c('v-card-text',{staticClass:"pb-0 pt-2 card-content d-flex align-center justify-center"},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.flow.archived)?_c('v-col',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" In the last "+_vm._s(_vm.selectedDateFilter)+" ")]):_c('v-col',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" All Time ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"font-weight-bold text-h4",staticStyle:{"min-height":"36px"}},[(!_vm.filteredStateSegments && _vm.loading > 0)?_c('v-skeleton-loader',{staticClass:"centered-skeleton",attrs:{"type":"heading"}}):(_vm.filteredStateSegments.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({},on),[_c('span',{staticClass:"hoverable"},[_vm._v(" "+_vm._s(_vm.total.toLocaleString())+" ")])])]}}])},[_c('div',_vm._l((_vm.filteredStateSegments),function(segment){return _c('div',{key:segment.label,staticClass:"d-flex align-center justify-space-between"},[_c('span',[_c('span',{staticClass:"mr-2",style:(_vm.statusStyle(segment.label))}),_c('span',[_vm._v(" "+_vm._s(segment.value.toLocaleString())+" ")])]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.humanLabel(segment.label))+" ")])])}),0)]):_c('div',[_vm._v("0")])],1),_c('div',{staticClass:"subtitle"},[_vm._v(" flow run"+_vm._s(_vm.total !== 1 ? 's' : '')+" ")])])]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"2"}},[_c('StackedLineChart',{attrs:{"segments":_vm.stateSegments,"colors":_vm.colors,"width":50,"vertical":""}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"font-weight-bold text-h5",staticStyle:{"min-height":"36px"}},[(!_vm.filteredStateSegments && _vm.loading > 0)?_c('v-skeleton-loader',{staticClass:"centered-skeleton",attrs:{"type":"heading"}}):(_vm.filteredStateSegments.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({},on),[_c('span',{staticClass:"hoverable"},[_vm._v(" "+_vm._s(_vm._f("roundTenths")(((_vm.filteredStateSegments[0].value / _vm.total) * 100)))+" ")]),_c('span',{staticClass:"text-subtitle-2"},[_vm._v("%")])])]}}])},[_c('div',_vm._l((_vm.filteredStateSegments),function(segment){return _c('div',{key:segment.label,staticClass:"d-flex align-center justify-space-between"},[_c('span',[_c('span',{staticClass:"mr-2",style:(_vm.statusStyle(segment.label))}),_c('span',[_vm._v(" "+_vm._s(_vm._f("filterOnePercent")(_vm._f("roundTenths")(((segment.value / _vm.total) * 100))))+"% ")])]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.humanLabel(segment.label))+" ")])])}),0)]):_vm._e()],1),_c('div',{staticClass:"subtitle"},[(!_vm.filteredStateSegments && _vm.loading > 0)?_c('v-skeleton-loader',{staticClass:"centered-skeleton",attrs:{"type":"text"}}):(_vm.filteredStateSegments.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.humanLabel(_vm.filteredStateSegments[0].label))+" ")]):_vm._e()],1)])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }