var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],staticClass:"pa-2 mt-2",attrs:{"tile":""}},[_c('CardTitle',{attrs:{"title":"Flow Versions","icon":"loop"}},[_c('v-text-field',{staticClass:"search",staticStyle:{"min-width":"400px"},attrs:{"slot":"action","dense":"","flat":"","solo":"","prepend-inner-icon":"search","placeholder":"Search by Version","hide-details":""},slot:"action",model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"ma-2 overflow-table",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"header-props":{ 'sort-icon': 'arrow_drop_up' },"headers":_vm.headers,"items":_vm.versions,"loading":_vm.loading > 0,"must-sort":"","page":_vm.page,"server-items-length":_vm.versionsCount,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 15, 20],
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right'
      },"calculate-widths":""},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.version",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":item.version}},[_c('router-link',{staticClass:"link",attrs:{"to":{
              name: 'flow',
              params: { id: _vm.flowGroupId, tenant: _vm.tenant.slug },
              query: { version: item.version }
            }}},[_vm._v(" "+_vm._s(item.version)+" ")])],1)]}},{key:"item.created",fn:function(ref){
            var item = ref.item;
return [_c('truncate',{attrs:{"content":_vm.formatTime(item.created)}},[_vm._v(" "+_vm._s(_vm.formDate(item.created))+" ")])]}},{key:"item.archived",fn:function(ref){
            var item = ref.item;
return [_c('truncate',{attrs:{"content":item.archived ? 'Archived' : 'Active'}},[_c('v-icon',{attrs:{"small":"","dark":"","color":item.archived ? 'accentPink' : 'green'}},[_vm._v(" "+_vm._s(item.archived ? 'archive' : 'pi-flow')+" ")])],1)]}},{key:"item.flow_runs",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"position-relative allow-overflow",staticStyle:{"height":"55px"}},[_c('LastTenRuns',{attrs:{"flow-id":item.id,"archived":item.archived}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }